.SnippetButton {
  color: var(--mb-color-brand);
  background-color: var(--mb-color-bg-light);
  margin-top: 0.5rem;

  &:hover {
    color: var(--mb-color-text-white);
    background-color: var(--mb-color-brand);
  }
}

.SnippetContent {
  &:hover {
    color: var(--mb-color-brand);
  }
}
