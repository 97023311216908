.SidesheetContent {
  &:not([data-css-specificity-hack="🤷‍♀️"]) {
    position: fixed;
    height: 100dvh;
    max-height: 100dvh;
    inset-inline-end: 0;
    border-radius: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

/* if you set overflow: auto on a child of this container can have the child scroll
 * rather than the parent: useful if you want tabs to scroll internally */
.FlexScrollContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column !important;
  height: 0;
}

.OverflowAuto {
  overflow: auto;
}

.InsightsTab {
  &:not([data-specificity-hack]) {
    &:disabled {
      cursor: pointer;
    }
  }
}

/* For divs that are not tabs but which sit next to the tabs */
.TabSibling {
  padding: 0.6875rem 0.5rem;
  color: var(--mb-color-text-dark);
  font-weight: bold;
  line-height: 1rem;

  &:hover {
    color: var(--mb-color-brand);
  }

  &:not([data-specificity-hack]) {
    &:disabled {
      cursor: pointer;
    }
  }
}
