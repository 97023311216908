.JoinCell {
  flex: 1;
  align-self: start;
}

.JoinConditionCell {
  flex: 1;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem;
}
