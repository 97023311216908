.labelWrapper {
  max-width: 100%;
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;

  &.labelActive {
    color: var(--mb-color-brand);
  }
}
