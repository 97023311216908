.SidebarContentMain {
  overflow-y: auto;
  height: 100%;
}

.FooterButton {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  padding-left: 4rem;
  padding-right: 4rem;
  border-radius: 99px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 13%);
}

.SidebarContentRoot {
  height: 100%;
}

.SidebarContentHeader {
  padding-top: 0.5rem;
  margin: 1rem 1.5rem;
}
