.Container {
  &.headVariant {
    .HeaderBadge {
      font-size: 1.25rem;
    }
  }
}

.HeaderBreadcrumbs {
  color: var(--mb-color-text-light);
  font-size: 0.8em;
  font-weight: bold;
  padding-left: 0.5em;
  padding-right: 0.5em;
  user-select: none;
}

.HeaderBadge {
  /* specificity */
  .HeaderBadgeIcon {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
  }
}
