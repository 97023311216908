::-webkit-input-placeholder {
  color: var(--mb-color-text-light);
}

:-moz-placeholder {
  color: var(--mb-color-text-light);
}

:-ms-input-placeholder {
  color: var(--mb-color-text-light);
}

.FormField {
  margin-bottom: 1.5em;
  color: var(--mb-color-text-medium);
}

.FormField.FormFieldError {
  color: var(--mb-color-error);
}

.FormLabel {
  display: block;
  font-weight: 900;
  font-size: 0.88em;
  color: inherit;
  margin-bottom: 0.5em;
}

.FormInput {
  font-family: var(--mb-default-font-family);
  font-weight: 700;
  font-size: 16px;
  color: var(--mb-color-text-dark);
  background-color: var(--mb-color-bg-white);
  padding: 0.75em;
  border: 1px solid var(--mb-color-border);
  border-radius: 8px;
  outline: none;
}

.FormField.FormFieldError .FormInput {
  border-color: var(--mb-color-error);
}

.noFocus:focus {
  outline: 0;
}

.FormInput:focus:not(.noFocus),
.FormField:hover .FormInput:not(.noFocus) {
  border-color: var(--mb-color-brand);
  transition: border 300ms ease-in-out;
}
