.ButtonRoot {
  transition: color 0.3s linear;

  &:hover {
    color: var(--mb-color-brand);
  }

  &.isSelected {
    color: var(--mb-color-brand);
  }
}
