.Container:not([data-css-specificity-hack="🤷"]) {
  cursor: pointer;
  overflow: hidden;

  --external-link-icon-color: var(--mb-color-text-light);

  &:hover {
    border-color: var(--mb-base-color-blue-40);
    background-color: var(--mb-color-bg-light);

    --external-link-icon-color: var(--mb-base-color-blue-40);
  }
}

.Disabled.Container:not([data-css-specificity-hack="🤷"]) {
  --mb-color-text-primary: var(--mb-color-text-tertiary);

  cursor: not-allowed;
  border-color: var(--mb-color-border);
  background-color: var(--mb-color-bg-light);
}
