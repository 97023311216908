.ViewSectionRoot {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @media screen and (min-width: 40em) {
    padding-left: 2rem;
    padding-right: 1rem;
  }
}
