.queryEditor {
  width: 100%;
  background-color: var(--mb-color-bg-light);
}

.dragHandleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: -4px;
  cursor: row-resize;
}

.dragHandle {
  width: 100px;
  height: 5px;
  background-color: var(--mb-color-border);
  border-radius: 4px;
}

.resizableBox {
  display: none;
  border-top: 1px solid var(--mb-color-border);

  &.open {
    display: flex;
  }
}
