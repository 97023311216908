.modal {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.modalHeader {
  flex-shrink: 0;
}

.modalBody {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.tableHeader {
  flex-shrink: 0;
  border-bottom: 1px solid var(--mb-color-border);
}

.modalFooter {
  flex-shrink: 0;
  border-top: 1px solid var(--mb-color-border);
}

.tbody {
  background: var(--mb-color-bg-light);
  overflow-y: auto;
  /* magic numbers:
   * 90dvh as modal has 5dvh padding on top/bottom
   * 189 is sum height of other elements in modal */
  min-height: min(25rem, calc(90dvh - 189px));
  max-height: min(40rem, calc(90dvh - 189px));
}

.column {
  &:first-child {
    width: 70%;
    padding: 0.5rem 0.5rem 0.75rem 2.5rem;
  }

  &:last-child {
    width: 30%;
    padding: 0.5rem 2.5rem 0.75rem 0.5rem;
  }
}

.cell {
  &:first-child {
    width: 70%;
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  }

  &:last-child {
    width: 30%;
    padding: 0.5rem 2.5rem 0.5rem 0.5rem;
  }
}

.tableRow {
  display: flex;

  &:first-child .col {
    padding-top: 0.75rem;
  }

  &:last-child .col {
    padding-bottom: 0.75rem;
  }
}
