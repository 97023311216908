.TagContainer {
  padding: 1.5rem 1.5rem 0 1.5rem;
  margin-bottom: 1.5rem;
  border-top: 1px solid var(--mb-color-border);
}

.TagName {
  font-weight: 900;
  margin-bottom: 2rem;
  align-self: flex-end;
  color: var(--mb-color-brand);
}
