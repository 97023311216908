.ExpressionListItem {
  display: flex;
  align-items: center;
  padding: 0 0.875rem;
  padding-right: 0.5rem;
  cursor: pointer;
  height: 2rem;
  color: var(--mb-color-text-dark);

  &.isHighlighted {
    color: var(--mb-color-text-white);
    background-color: var(--mb-color-brand);
  }
}

.ExpressionListFooter {
  height: 2rem;
  color: var(--mb-color-text-medium);
  display: flex;
  align-items: center;
  padding-left: 0.875rem;
  margin-top: 12px;

  &.isHighlighted {
    color: var(--mb-color-text-white);
    background-color: var(--mb-color-brand);
  }
}

.GroupTitle {
  font-weight: bold;
  font-size: 12px;
  color: var(--mb-color-text-medium);
  pointer-events: none;
  margin-top: 12px;

  &:first-child {
    margin-top: 0;
  }
}

.PopoverHoverTarget {
  margin-left: auto;
  padding: 0.3125rem 0;
  visibility: hidden;
}
