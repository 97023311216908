.Root {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  font-weight: bold;
  border-radius: 6px;
  color: var(--mb-color-text-white);
  background-color: var(--mb-color-summarize);
  transition:
    background 300ms linear,
    border 300ms linear;
  min-height: 34px;
  min-width: 34px;
  cursor: pointer;

  &:hover {
    background-color: color-mix(
      in srgb,
      var(--mb-color-summarize),
      transparent 20%
    );
    border-color: color-mix(
      in srgb,
      var(--mb-color-summarize),
      transparent 20%
    );
  }
}

.AggregationName {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.RemoveIcon {
  display: flex;
  margin-left: auto;
  opacity: 0.4;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
}
