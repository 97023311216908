.SavedEntityPickerRoot {
  display: flex;
  width: 620px;
  overflow: hidden;
  border-top: 1px solid var(--mb-color-border);

  @media screen and (max-width: 40em) {
    flex-direction: column;
    width: 300px;
    overflow: auto;
  }
}

.CollectionsContainer {
  display: flex;
  flex-direction: column;
  min-width: 310px;
  background-color: var(--mb-color-bg-light);
  overflow: auto;

  @media screen and (max-width: 40em) {
    min-height: 220px;
    border-bottom: 1px solid var(--mb-color-border);
  }
}

.BackButton {
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: var(--mb-color-text-dark);
  border-bottom: 1px solid var(--mb-color-border);
  padding: 1rem;

  &:hover {
    color: var(--mb-color-brand);
  }
}
