.StyledSelectButton {
  &.hasValue {
    background-color: var(--mb-color-brand);
    border-color: var(--mb-color-brand);

    .StyledSelectIcon {
      color: var(--mb-color-text-white);
    }

    /* this class is needed because of high specificity of SelectButton.Content */
    .StyledSelectButtonContent {
      color: var(--mb-color-text-white);
    }
  }
}
