.Divider {
  height: 1px;
  width: 100%;
  background-color: var(--mb-color-bg-medium);
}

.MainFormContainer {
  padding: 1.5rem 1.5rem 0;

  .SelectButton {
    color: var(--mb-color-text-dark);
    transition: border 0.3s;
    outline: none;

    &:focus {
      border-color: var(--mb-color-brand);
    }
  }
}
