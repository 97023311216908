.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.RunButtonWithTooltipStyled {
  margin: 1rem;
  margin-top: auto;
  height: 40px;
  width: 40px;
}

.SidebarButton {
  padding: 0;
  margin-top: 1rem;
}
