:where(:global(.mb-wrapper)) {
  --transition-theme-change: background-color 1s linear, border-color 1s linear,
    color 1s linear;
}

.transitionColor {
  transition: color 0.3s linear;
}

.transitionThemeChange {
  transition: var(--transition-theme-change);
}

@media (prefers-reduced-motion) {
  :where(:global(.mb-wrapper)) {
    --transition-theme-change: none;
  }
}
