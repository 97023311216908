.root {
  display: flex;
  cursor: pointer;
  color: var(--mb-color-filter);
  background-color: color-mix(in srgb, var(--mb-color-filter) 20%, transparent);
  border-radius: 0.75rem;
}

.icon {
  flex-shrink: 0;
}
